import { useState, useRef, useEffect } from "react";
import iconMagnifier from './../../img/zoom.png';
import iconEnlarge from './../../img/enlarge.png';
import Text from "./Text";
import _ from 'underscore';

const ZoomImageThumb = function(props) {
	const [zoomImageOpen, setZoomImageOpen] = useState(false);
	const [zoomImageVisible, setZoomImageVisible] = useState(false);

	return <div className="relative">
		<img src={props.thumb} 
			className="cursor-pointer transition-all duration-[600ms] hover:scale-105" onClick={() => {
				setZoomImageOpen(!zoomImageOpen);
				setTimeout(() => setZoomImageVisible(true), 100);
			}} 
		/>
		<img src={iconEnlarge} 
			className="w-[40px] absolute right-0 bottom-0 cursor-pointer" 
			onClick={() => {
				setZoomImageOpen(!zoomImageOpen);
				setTimeout(() => setZoomImageVisible(true), 100);
			}} 
		/>
		{
			zoomImageOpen && <div className={'fixed inset-0 z-[2000] flex items-center justify-center transition-opacity duration-[300ms]'+(zoomImageVisible ? ' opacity-100' : ' opacity-0')}>
				<div className="absolute inset-0 bg-sam_dark/90" onClick={() => {
					setZoomImageVisible(false);
					setTimeout(() => setZoomImageOpen(false), 500);
				}}
				style={{
					cursor: 'url(/img/cursor-close.gif), auto'
				}}
			/>
				{
					props.children
				}
			</div>
		}
	</div>
}

const ZoomImageText = function(props) {
	const [selectedHotspot, setSelectedHotspot] = useState(null);

	let onHotspotDataHover = (hotspot) => {
		setSelectedHotspot(hotspot);
	}

	return <div className="lg:flex items-stretch gap-12">
		<Text className={props.textSizing || 'basis-1/2'} 
			content={props.textContent} 
			onHotspotDataHover={onHotspotDataHover} 
		/>
		<div className={props.imageSizing || 'basis-1/2 max-w-1/2'}>
			<ZoomImage hotspots={props.hotspots || null} 
				selectedHotspot={selectedHotspot}
				showHotspotsOnZoom={true}
				className={'text-xl max-w-prose'} 
				thumb={props.thumb} 
				src={props.src} />
			{
				props.imageText &&
				<div className="mt-5 text-sam_accent" dangerouslySetInnerHTML={{__html: props.imageText}} />
			}
		</div>
	</div>
}

const ZoomImage = function(props) {
	const [imageLoaded, setImageLoaded] = useState(false);
	const [initialized, setInitialized] = useState(false);
	const [mouseEntered, setMouseEntered] = useState(false);
	const [magnifierVisible, setMagnifierVisible] = useState(false);
	const [hotspotText, setHotspotText] = useState(null);
	const [visibleHotspot, setVisibleHotspot] = useState(false);
	const [hotspotTextVisible, setHotspotTextVisible] = useState(false);
	const [selectedHotspot, setSelectedHotspot] = useState(false);

	const image = useRef(null);
	const magnifier = useRef(null);
	const magnifierImage = useRef(null);

	let between = (x, min, max) => x >= min && x <= max;

	useEffect(() => {
		setTimeout(() => setInitialized(true), 500);
	}, []);

	const mouseMoveHandler = (event) => {
		setMouseEntered(true);
		setMagnifierVisible(true)

		var rect = event.target.getBoundingClientRect();
		var x = event.clientX-rect.left;
		var y = event.clientY-rect.top;

		magnifier.current.style.left = x-216+'px';
		magnifier.current.style.top = y-216+'px';

		let imageWidth = image.current.offsetWidth;
		let imageHeight = image.current.offsetHeight;

		let magnifierImageWidth = magnifierImage.current.offsetWidth;
		let magnifierImageHeight = magnifierImage.current.offsetHeight;

		magnifierImage.current.style.left = -((x/imageWidth)*magnifierImageWidth)+216+'px';
		magnifierImage.current.style.top = -((y/imageHeight)*magnifierImageHeight)+216+'px';

		if (props.hotspots) {
			let leftPos = (x/imageWidth)*magnifierImageWidth;
			let topPos = (y/imageHeight)*magnifierImageHeight;

			let closest = props.hotspots.filter((hotspot) => {
				return between(leftPos, hotspot.left, hotspot.right) && between(topPos, hotspot.top, hotspot.bottom);
			});

			setHotspotText(closest.length > 0 ? closest[0].text : null);

			if (closest.length > 0) {
				if (props.showHotspotsOnZoom) {
					setVisibleHotspot(closest[0]);
				}
	
				if (!hotspotTextVisible) {
					setHotspotText(closest[0].text);

					setHotspotTextVisible(true);
				}
			}
			else {
				setVisibleHotspot(false);
				setHotspotTextVisible(false);

				setTimeout(() => setHotspotText(null), 300);
			}
		}
	}

	useEffect(() => {
		let _selectedHotspot;

		try {
			_selectedHotspot = _.findWhere(props.hotspots, {id: props.selectedHotspot});

			let imageWidth = image.current.offsetWidth;
			let imageHeight = image.current.offsetHeight;
	
			let magnifierImageWidth = magnifierImage.current.offsetWidth;
			let magnifierImageHeight = magnifierImage.current.offsetHeight;

			let widthRatio = imageWidth/magnifierImageWidth;
			let heightRatio = imageHeight/magnifierImageHeight;

			_selectedHotspot.top = _selectedHotspot.top*heightRatio;
			_selectedHotspot.bottom = (magnifierImageHeight-_selectedHotspot.bottom)*heightRatio;
			_selectedHotspot.left = _selectedHotspot.left*widthRatio;
			_selectedHotspot.right = (magnifierImageWidth-_selectedHotspot.right)*widthRatio;
		}
		catch (e) {}

		if (props.selectedHotspot) {
			setMouseEntered(true);
		}
		setSelectedHotspot(_selectedHotspot || null);
	}, [props.selectedHotspot]);

	return <div className={'relative zoom-image'+(mouseEntered ? ' activated' : '')}>
		<img className={props.className || ''} src={props.thumb} ref={image} onLoad={() => {
			setImageLoaded(true);
			//setLandscapeFormat(image.current.offsetWidth > image.current.offsetHeight);
		}} onMouseMove={mouseMoveHandler} onMouseEnter={() => {
			if (initialized) {
				setMagnifierVisible(true);
			}
		}} onMouseLeave={() => setMagnifierVisible(false)}/>

		<div className={'absolute border-2 border-sam_accent shadow-[0_0_185px_26px_black] transition-all duration-[600ms] pointer-events-none'+(selectedHotspot ? ' opacity-100' : ' opacity-0')} style={selectedHotspot ? {
			left: selectedHotspot.left,
			top: selectedHotspot.top,
			right: selectedHotspot.right,
			bottom: selectedHotspot.bottom
		} : null} />

		<div className={'magnifier transition-opacity duration-300'+(magnifierVisible ? ' visible' : '')} ref={magnifier}>
			<div className="absolute" ref={magnifierImage}>
				<img src={props.src} />
				{
					visibleHotspot && <div className={'absolute border-2 border-sam_accent shadow-[0_0_185px_26px_black] transition-all duration-[600ms] pointer-events-none'+(visibleHotspot ? ' opacity-100' : ' opacity-0')} 
						style={{
							left: visibleHotspot.left,
							top: visibleHotspot.top,
							width: visibleHotspot.right-visibleHotspot.left,
							height: visibleHotspot.bottom-visibleHotspot.top
						}}
					/>
			
				}
			</div>
			{
				hotspotText && <div className={'hotspot-text transition-opacity duration-300'}>{hotspotText}</div>
			}
		</div>
		{
			!props.disableIndicatior &&
			<div className={'absolute inset-0 bg-sam_dark/50 pt-40 text-center items-center transition-opacity duration-[800ms] pointer-events-none'+(mouseEntered ? ' opacity-0' : '')}>
				<img src={iconMagnifier} className="w-[80px] inline-block intro-icon" />
			</div>
		}
	</div>
}

export {
	ZoomImage,
	ZoomImageThumb,
	ZoomImageText
}