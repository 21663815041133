export default {
	exhibitionPageRoot: 'syning',
	//apiRoot: '/django/api/',
	//apiRoot: 'http://130.208.108.18:8000/api/',
	//apiRoot: 'http://syning.arnastofnun.is/django/api/',
	apiRoot: '/django/api/',
	//videoRoot: 'http://syning.arnastofnun.is/video/',
	videoRoot: '/video/',
	//mediaRoot: 'http://syning.arnastofnun.is/',
	mediaRoot: '/'
}
