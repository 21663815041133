import { useState, useEffect } from "react";
import config from "../../config";

export default function OpeningHours(props) {
	const [openingHoursData, setOpeningHoursData] = useState(null);

	useEffect(() => {
		if (!openingHoursData) {
			fetch(config.apiRoot+'openinghours_text')
				.then(res => res.json())
				.then(json => {
					
					setOpeningHoursData(json.results);
				});
		}
	}, []);

	return <div className={props.className}>
		<h2>{window.l('Afgreiðslutími')}</h2>
		{
			openingHoursData && <table className="w-full">
				<tbody>
					{
						openingHoursData.map((item, index) => <tr key={index}>
							<td>{item['label_'+props.currentLang]}</td>
							<td className="text-sam_red">{item['openinghours_'+props.currentLang]}</td>
						</tr>)
					}
				</tbody>
			</table>
		}

	</div>;
}
