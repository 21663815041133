import config from "./config";

const helpers = {
	days: [
		{
			is: 'Sunnudagur',
			en: 'Sunday'
		},
		{
			is: 'Mánudagur',
			en: 'Monday'
		},
		{
			is: 'Þriðjudagur',
			en: 'Thuesday'
		},
		{
			is: 'Miðvikudagur',
			en: 'Wednesday'
		},
		{
			is: 'Fimmtudagur',
			en: 'Thursday'
		},
		{
			is: 'Föstudagur',
			en: 'Friday'
		},
		{
			is: 'Laugardagur',
			en: 'Saturday'
		}
	],

	dayList: (list, currentLang) => {
		return list.length == 1 ? helpers.days[list[0]][currentLang]
			: list.length == 2 ? helpers.days[list[0]][currentLang]+' '+window.l('og')+' '+helpers.days[list[1]][currentLang].toLowerCase()
			: helpers.days[list[0]][currentLang]+' '+window.l('til')+' '+helpers.days[list[list.length-1]][currentLang].toLowerCase()
	},

	_dayList: (list) => {
		return list.length == 1 ? list.join(', ')
			: list.length == 2 ? list[0]+' '+window.l('og')+' '+list[1].toLowerCase()
			: list[0]+' '+window.l('til')+' '+list[list.length-1].toLowerCase()
	},

	formatOpeningHours: (item) => item.open_from && item.open_to ? 
		item.open_from.substring(0, 2)+' - '+item.open_to.substring(0, 2)
		: '',

	mediaUrl: (url) => url ? url.replace('http://130.208.108.18:8000/', config.mediaRoot) : url,

	twoDigits: (n) => {
		return parseInt(n) < 10 ? '0'+n : n;
	},

	formatDate: (date, brake) => {
		let months = [
			'',
			'janúar',
			'febrúar',
			'mars',
			'apríl',
			'maí',
			'júní',
			'júlí',
			'ágúst',
			'september',
			'október',
			'nóvember',
			'desember'
		]

		let frags = date.split('-');

		return parseInt(frags[2])+'. '+months[parseInt(frags[1])]+(brake ? '<br/>' : ' ')+frags[0];
	},
	
	formatDuration: (millis) => {
		var minutes = Math.floor(millis / 60000);
		var seconds = ((millis % 60000) / 1000).toFixed(0);
		return helpers.twoDigits(minutes)+':'+helpers.twoDigits(seconds);
	},
}

export default helpers;